import * as React from "react";
import { memo } from "react";
const SvgLeftArrow = (props) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 48,
    height: 48,
    fill: "none",
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "mask",
    {
      id: "ccae81d1-6261-44e0-be58-c2b48e67a4dd-a",
      width: 48,
      height: 48,
      x: 0,
      y: 0,
      maskUnits: "userSpaceOnUse",
      style: {
        maskType: "alpha"
      }
    },
    /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M0 0h48v48H0z" })
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      stroke: "currentColor",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: 2,
      d: "M21.72 17 15 24l6.72 7"
    }
  ),
  /* @__PURE__ */ React.createElement(
    "path",
    {
      stroke: "currentColor",
      strokeLinecap: "round",
      strokeWidth: 2,
      d: "M16.27 24.03H32"
    }
  )
);
const Memo = memo(SvgLeftArrow);
export default Memo;
