import * as React from "react";
import { memo } from "react";
const SvgGiftcardOrange = (props) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    fill: "none",
    ...props
  },
  /* @__PURE__ */ React.createElement("g", { fill: "#F68A1F", clipPath: "url(#1df8f516-de9c-4842-bc24-1bd2c1fae2c6-a)" }, /* @__PURE__ */ React.createElement("path", { d: "M24 12.92c0 1.95.03 3.84 0 5.73-.04 1.58-.95 2.5-2.54 2.52-3.49.03-6.97 0-10.55 0v-6.4c1.1.34 1.7 2.65 3.12 1.13 1.25-1.35-.78-1.9-1.26-3zM.03 12.86h6.41C6.09 14 3.78 14.6 5.31 16.02c1.35 1.25 1.89-.82 3-1.23v6.38c-2 0-4.03.08-6.05-.03-1.38-.07-2.17-.98-2.2-2.37-.08-1.94-.03-3.88-.03-5.91M8.37 2v3.7c-1.38-.22-2.67-.07-3.68.96-1.02 1.04-1.26 2.24-.94 3.66H.03c0-2.07-.1-4.15.04-6.22C.16 2.74 1.15 2.02 2.54 2z" }), /* @__PURE__ */ React.createElement("g", { clipPath: "url(#9b7b9058-71aa-45a0-89d2-35b1744e39f1-b)" }, /* @__PURE__ */ React.createElement("path", { d: "M24 10.35h-8.48c.23-3.42-1.25-4.92-4.64-4.66V2.04c3.7 0 7.42-.08 11.13.05 1.27.04 1.96 1.1 1.97 2.42.02 1.9.02 3.8.02 5.84M8.38 10.37c-1.13.03-2.25.24-2.34-1.23a1.03 1.03 0 0 1 1.13-1.12c1.46.12 1.25 1.23 1.2 2.35M10.84 10.4c-.06-1.1-.27-2.2 1.17-2.36a1.02 1.02 0 0 1 1.16 1.09c-.05 1.47-1.2 1.27-2.33 1.27" }))),
  /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "1df8f516-de9c-4842-bc24-1bd2c1fae2c6-a" }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M0 2h24v19.2H0z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "9b7b9058-71aa-45a0-89d2-35b1744e39f1-b" }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M0 2h24v19H0z" })))
);
const Memo = memo(SvgGiftcardOrange);
export default Memo;
